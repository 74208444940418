import { memo, useEffect } from 'react'
import styled, { css } from 'styled-components'

import { Box, Icon, IconButton, media } from '@cutover/react-ui'
import { useActiveRightPanelValueType, useToggleRightPanel } from 'main/components/layout/right-panel'
import { useLanguage } from 'main/services/hooks'
import { ActiveRunbookModel, ActiveRunbookVersionModel, ConfigModel, RunbookViewModel } from 'main/data-access'
import { useIsTemplateLocked } from 'main/components/shared/hooks/runbook'
import { ModalActiveType } from 'main/recoil/runbook'

export const RunbookRightNavigation = memo(() => {
  const { t } = useLanguage('runbook', { keyPrefix: 'rightNavigation' })
  const isAiFeaturesEnabled = ConfigModel.useIsFeatureEnabled('ai_features')
  const runbookId = ActiveRunbookModel.useId()
  const runbook = ActiveRunbookModel.useGet()
  const activeRightPanelType = useActiveRightPanelValueType()
  const openModal = RunbookViewModel.useAction('modal:open')
  const runbookType = ActiveRunbookModel.useRunbookType()
  const isChangeRequest = runbookType.change_request_types.length > 0

  const toggleAiSuggestions = useToggleRightPanel('ai-suggestions-list')
  const toggleRunbookIntegrationsPanel = useToggleRightPanel('runbook-integrations')
  const toggleRunbookEdit = useToggleRightPanel('runbook-edit')
  const togglePeoplePanel = useToggleRightPanel('user-team-details-edit', () => true)
  const toggleChangeRequests = useToggleRightPanel('runbook-change-requests')
  // open panel check function here so that it will only toggle closed when its viewing the full list of runbook comments. If it is viewing
  // a specific task's comments, switch to viewing all comments.
  const toggleCommentsPanel = useToggleRightPanel('runbook-comments', openPanel => !openPanel.taskId)
  const resetNewCommentsCount = RunbookViewModel.useAction('newCommentsCount:reset')
  const { hasRunbookIntegrations } = ConfigModel.useGet()

  // Show edit or info icon depending if panel can be edited - should be refactored into single 'canEditRunbook'
  // and passed down from runbook page wrapper, so logic here matches what it is within the edit panel
  const canUpdateRunbook = ActiveRunbookModel.useCan('update')
  // Note: do we really need a separate permission for this? Should it be part of the update permission?
  const canSendAdHocComms = ActiveRunbookVersionModel.useCan('adhoc_comms')

  const { template_type: templateType } = ActiveRunbookModel.useGet()
  const { id: runbookVersionId, approval_status: approvalStatus } = ActiveRunbookVersionModel.useGet()
  const isTemplateLocked = useIsTemplateLocked(approvalStatus)
  const newCommentsCount = RunbookViewModel.useGet('newCommentsCount')
  const runbookPanelIcon = canUpdateRunbook && !isTemplateLocked ? 'edit' : 'info'
  const editIconLabel =
    templateType === 'default' ? t('editTemplate') : templateType === 'snippet' ? t('editSnippet') : t('editRunbook')
  const infoIconLabel =
    templateType === 'default'
      ? t('templateDetails')
      : templateType === 'snippet'
      ? t('snippetDetails')
      : t('runbookDetails')
  const iconLabel = runbookPanelIcon === 'edit' ? editIconLabel : infoIconLabel

  useEffect(() => {
    if (activeRightPanelType === 'runbook-comments') {
      resetNewCommentsCount()
    }
  }, [activeRightPanelType])

  return (
    <NavContainer>
      <IconButton
        icon={runbookPanelIcon}
        onClick={() => toggleRunbookEdit({ runbookId })}
        label={iconLabel}
        isActive={activeRightPanelType === 'runbook-edit' || activeRightPanelType === 'runbook-copies'}
        data-testid="runbook-edit-button"
      />
      {isChangeRequest && (
        <Box css="position: relative">
          <IconButton
            icon="remedy"
            onClick={() => toggleChangeRequests({ runbookId })}
            label={t('changeRequests')}
            isActive={activeRightPanelType === 'runbook-change-requests'}
            data-testid="runbook-change-requests"
          />
          {runbook.change_requests_count === 0 && (
            <Icon icon="alert" color="warning" size="small" css="position: absolute; right: -2px; top: -2px;" />
          )}
        </Box>
      )}
      <IconButton
        icon="users"
        onClick={() => togglePeoplePanel({ initialScreen: 'users-and-teams' })}
        label={t('usersAndTeams')}
        isActive={activeRightPanelType === 'user-team-details-edit'}
        data-testid="button-view-userteam"
      />
      <IconButton
        icon="message"
        onClick={() => toggleCommentsPanel({})}
        label={
          newCommentsCount > 0 ? t('runbookCommentsNewComments', { count: newCommentsCount }) : t('runbookComments')
        }
        isActive={activeRightPanelType === 'runbook-comments'}
        {...(newCommentsCount > 0 ? { badge: { label: newCommentsCount, type: 'primary' } } : {})}
      />
      {canSendAdHocComms && templateType === 'off' && (
        <IconButton
          icon="phone"
          onClick={() => openModal({ type: 'runbook-ad-hoc-comms' } as ModalActiveType)}
          label={t('adHocComms')}
          data-testid="ad-hoc-comms"
        />
      )}
      {hasRunbookIntegrations && (
        <IconButton
          icon="integrations"
          onClick={() => toggleRunbookIntegrationsPanel({ runbook, runbookVersionId })}
          label={t('runbookIntegrations')}
        />
      )}
      {isAiFeaturesEnabled && (
        <IconButton
          badge={{ color: 'feature-pink' }}
          icon="rocket"
          isActive={activeRightPanelType === 'ai-suggestions-list'}
          onClick={() => toggleAiSuggestions({ runbookId })}
          label={t('aiSuggestions')}
        />
      )}
    </NavContainer>
  )
})

const NavContainer = styled(Box)`
  flex-direction: column;
  align-items: center;
  gap: 12px;
  padding-top: 14px;
  ${media.sm(css`
    flex-direction: row;
    justify-content: center;
    gap: 32px;
  `)}
`
